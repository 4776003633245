import {Theme} from '@mui/material';
import colors from '../colors';
import {Font} from '../theme';

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    popover: true;
    menu: true;
    dialog: true;
    panel: true;
    summary: true;
    form: true;
    'form-grow': true;
    quill: true;
    htmlEditor: true;
    'inline-menu': true;
    daySummary: true;
    notification: true;
  }
}
/**
 * todo boxShadows have a theme setting
 *
 * Notes:
 *    avoid using colors const and hardcoded colors to allow changing themes
 *    color cannot be a css variable but otherwise try to use css variables
 *
 */
export const cardTheme = (themeSettings: Theme): Theme['components'] => ({
  MuiCard: {
    styleOverrides: {
      root: {
        '&.fill': {
          height: `calc(100% - var(--appBarHeight) - ${themeSettings.spacing(2)})`,
          display: 'flex',
          flexDirection: 'column',
        },

        '&.scrollContent': {
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },

    variants: [
      {
        props: {variant: 'popover'},

        style: {
          padding: themeSettings.spacing(2),
          minWidth: 300,
          width: 300,
          backgroundColor: themeSettings.palette.background.paper,

          '& > *': {
            margin: themeSettings.spacing(2),
            padding: 0,
          },

          '& .MuiCardHeader-root': {
            border: 'none',
          },
        },
      },

      {
        props: {variant: 'menu'},

        style: {
          minWidth: 250,
          backgroundColor: themeSettings.palette.background.paper,
        },
      },
      {
        props: {variant: 'htmlEditor'},

        style: {
          overflow: 'visible',
          display: 'flex',
          width: '100%',

          '& .MuiCardContent-root': {
            overflow: 'hidden',
            width: '100%',
            margin: 0,
            boxShadow: `inset 0 0 0 1px ${themeSettings.palette.divider}`,
            zIndex: 1,
          },

          '& [role="textbox"]': {
            fontSize: '14px',
            height: '140px',
            padding: themeSettings.spacing(2),
            lineHeight: '1.42',
            whiteWpace: 'pre-wrap',
            wordWrap: 'break-word',
            borderRadius: '3px',
            overflowY: 'scroll',
            overflowX: 'hidden',
          },

          '& [role="textbox"][disabled]': {
            background: colors.veryLightBlue,
            cursor: 'not-allowed',
            pointerEvents: 'none',
          },

          '& p': {
            margin: 0,
          },
        },
      },
      {
        props: {variant: 'dialog'},

        style: {
          width: 'var(--dialogWidth)',
          backgroundColor: colors.lightDatePickerRange,
          padding: 0,
          paddingBottom: 0,

          '& > *': {
            margin: themeSettings.spacing(3),
            padding: 0,
          },

          '& .MuiCardContent-root .MuiSvgIcon-root': {
            color: colors.lightIconDefault,
          },

          '& .MuiCardActions-root': {
            marginTop: themeSettings.spacing(3),
            padding: 0,
          },

          '& .MuiCardHeader-root': {
            padding: themeSettings.spacing(2),
            margin: 0,
            backgroundColor: themeSettings.palette.background.paper,
          },
        },
      },

      {
        props: {variant: 'panel'},

        style: {
          height: `calc(100% - var(--appBarHeight) - ${themeSettings.spacing(2)})`,
          display: 'flex',
          flexDirection: 'column',
          width: 400,
          backgroundColor: colors.veryLightBlue,
          padding: 0,

          '& .MuiAccordion-root': {
            border: 'none',
          },

          '& .MuiAccordion-region': {
            backgroundColor: colors.veryLightBlue,
          },

          '& .MuiAccordionSummary-root, & .MuiAccordionSummary-root.Mui-expanded': {
            /** UX style guide settings    */
            minHeight: '50px',
            backgroundColor: colors.lightTableRowEven,
          },

          '& .MuiAccordionSummary-content': {
            fontWeight: 'normal',
          },

          '& .MuiCardHeader-root': {
            padding: '9px 18px',
          },

          '& .MuiCardContent-root': {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
          },

          '& .MuiCardHeader-title': {
            fontFamily: Font.semiBold,
          },

          '& .MuiCardActions-root': {
            padding: themeSettings.spacing(2),
            margin: 0,
          },

          '& .MuiTabs-root': {
            minHeight: '74px',
            borderBottom: `solid 1px ${colors.lightDividerBlack}`,
          },

          '& .vertical-link-lines': {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
          },

          '& .vertical-link-lines::before, & .vertical-link-lines::after': {
            content: '""',
            color: colors.lightIconDefault,
            outline: `1px solid ${colors.lightIconDefault}`,
            display: 'block',
            position: 'absolute',
            left: '50%',
            top: '-0.5em',
            height: '0.5em',
            textAlign: 'center',
          },

          '& .vertical-link-lines::after': {
            top: '1.25em',
          },
        },
      },

      {
        props: {variant: 'summary'},

        style: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '310px',
          height: '100px',
          border: `solid 1px ${colors.lightDividerBlack}`,
          borderRadius: '4px',
          textAlign: 'center',

          '& h3': {
            fontSize: '28px',
            fontWeight: 'normal',
          },

          '& h4': {
            fontSize: '16px',
            fontWeight: 'normal',
          },
        },
      },

      {
        props: {variant: 'form'},

        style: {
          backgroundColor: 'transparent',

          '& .MuiCardContent-root': {
            margin: 'var(--spacing2)',
            padding: 0,
            width: 'auto',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            gap: 'var(--spacing2)',
            columnGap: 'var(--spacing6)',
            alignItems: 'flex-start',
          },

          '& .MuiCardContent-root > *': {
            width: 'var(--inputsWidth)',
          },

          '& .MuiCardContent-root > * > *': {
            width: '100%',
          },

          '& .MuiCardContent-root > .KDivider': {
            padding: 0,
            margin: 0,
            flexGrow: 1,
            width: '100%',
            maxWidth: 'none',
          },
        },
      },

      {
        props: {variant: 'form-grow'},

        style: {
          backgroundColor: 'transparent',

          '& .MuiCardContent-root': {
            margin: 0,
            padding: 0,
            display: 'flex',
            flexDirection: 'row',
            gap: 'var(--spacing2)',
            columnGap: 'var(--spacing6)',
            alignItems: 'center',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            width: '100%',
          },

          '& .MuiCardContent-root > *:not(.KDivider)': {
            width: 'var(--inputsWidth)',
          },

          '& .MuiCardContent-root > * > *': {
            width: '100%',
          },

          '& .MuiCardContent-root > .KDivider': {
            width: '100%',
          },

          '&  .MuiCardContent-root > *:not(.KDivider)': {
            flexGrow: 1,
          },
        },
      },

      {
        props: {variant: 'inline-menu'},

        style: {
          backgroundColor: 'transparent',
          marginRight: '-3px',

          '& ul': {
            margin: 0,
            gap: 0,
            justifyContent: 'flex-start',
            borderRadius: '3px',
          },

          '& li': {
            boxShadow: 'inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 1px 0 rgba(16, 22, 26, 0.1)',
            justifyContent: 'center',
            flex: '1 1 0',
            width: 0,
          },

          '& li, & li.select:hover': {
            backgroundColor: colors.calcite,
          },

          '& li .MuiTypography-root, & li.select:hover .MuiTypography-root': {
            color: colors.dark,
            textAlign: 'center',
          },

          '& li:hover, & li.select': {
            backgroundColor: colors.cobalt,
          },

          '& li:hover .MuiTypography-root, & li.select .MuiTypography-root': {
            color: colors.calcite,
            textAlign: 'center',
          },
        },
      },

      {
        props: {variant: 'daySummary'},

        style: {
          '& .moreTag .MuiTypography-root': {
            fontSize: 10,
            color: 'inherit',
            lineHeight: 2,
          },

          '& .moreTag .other': {
            paddingLeft: 30,
          },

          '& .moreTag:not(.PERIOD)+.moreTag .displayName': {
            marginTop: 10,
          },

          '& .moreTag.PERIOD .displayName': {
            color: colors.dark,
          },

          '& .moreTag.PERIOD *:not(.displayName)': {
            display: 'none',
          },

          '& .moreTag.DEVICE .displayName': {
            color: colors.wear,
          },

          '& .moreTag.MOBILE .displayName': {
            color: colors.task,
          },
        },
      },

      {
        props: {variant: 'notification'},

        style: {
          position: 'relative',
          minWidth: 250,
          backgroundColor: themeSettings.palette.background.paper,

          // container
          '& + &': {
            borderTop: `solid 1px ${colors.lightDividerBlack}`,
          },

          '&.selected': {
            backgroundColor: colors.lightTableRowOdd,
          },

          '&.expanded': {
            width: 765,
          },

          '&.list:not(.preview)': {
            padding: '20px 30px',
            maxHeight: 90,
          },

          '&.preview': {
            padding: 10,
          },

          // header
          '& .MuiCardHeader-root, & .MuiCardHeader-root .MuiCardHeader-title': {
            backgroundColor: 'transparent',
            fontWeight: 'normal',
            letterSpacing: 'normal',
          },

          '& .notification-header': {
            gap: '4px',
          },

          '& .MuiCardHeader-content': {
            maxWidth: '100%',
          },

          '& .notification-title': {
            fontSize: 15,
            color: colors.notificationText,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },

          '& .notification-created': {
            fontSize: 13,
            color: colors.pagination,
            whiteSpace: 'nowrap',
          },

          '& .notification-badges-left': {
            position: 'absolute',
            left: 0,
            top: 15,
          },

          '& .notification-badges-right': {
            position: 'absolute',
            right: 0,
            top: 15,
          },

          '& .notification-actions': {
            display: 'flex',
            // todo glitch here as width is defaulting to 100% and having to set width for 1 action
            width: 60,
            maxHeight: 26,
            margin: '-30px auto 0',
            padding: '5px 0',
            borderRadius: 4,
            border: `1px solid ${colors.lightDividerBlack}`,
            translate: '0 -50%',
            backgroundColor: colors.calcite,
          },

          '&:not(.expanded) .MuiCardHeader-root': {
            border: 'none',
            padding: 0,
          },

          '&.expanded .MuiCardHeader-root': {
            padding: '10px',
          },

          '&.expanded .notification-title': {
            fontSize: 16,
            color: colors.dark,
            textOverflow: 'clip',
            whiteSpace: 'wrap',
            maxHeight: '6.45em',
            lineHeight: '1.29em',
            display: '-webkit-box',
            lineClamp: 5,
            WebkitLineClamp: 5,
            WebKitBoxOrient: 'vertical',
          },

          '&.preview .notification-title': {
            color: colors.dark,
          },

          // content
          '&.expanded .MuiCardContent-root': {
            padding: '30px 20px',
          },

          '&.list .MuiCardContent-root div': {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            color: colors.pagination,
          },

          '&.preview .MuiCardContent-root': {
            color: colors.pagination,
          },

          '&.preview .MuiCardContent-root > div': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            color: colors.pagination,
            maxHeight: '3.87em',
            lineHeight: '1.29em',
            display: '-webkit-box',
            lineClamp: 3,
            WebkitLineClamp: 3,
            WebKitBoxOrient: 'vertical',
          },

          // tags
          '&:not(.expanded) .notification-tags': {
            display: 'none',
          },

          '& .notification-tag-collapsed': {
            display: 'inline-block',
            width: 6,
            height: 6,
            borderRadius: '50%',
            backgroundColor: colors.lightBadges5Danger,
            margin: '2px 0',
          },

          '& .notification-badges-left .notification-tag-collapsed': {
            display: 'block',
            margin: '0 10px',
          },

          '& .notification-badges-header': {
            flexGrow: 1,
          },

          '& .notification-tag.NON_COMPLIANCE': {
            backgroundColor: colors.lightBadges5Danger,
          },

          '& .notification-tag.ACCOUNT_SUPPORT': {
            backgroundColor: '#00989e',
          },

          '& .notification-tag.DATA_QUALITY': {
            backgroundColor: colors.lightBadges3Success,
          },

          // breadcrumbs
          '&.list .notification-breadcrumb, &.preview .notification-breadcrumb': {
            display: 'none',
          },

          // remove color change from first and last crumbs
          '& .notification-breadcrumb .MuiBreadcrumbs-li:first-of-type, &  .notification-breadcrumb .MuiBreadcrumbs-li:first-of-type + li.MuiBreadcrumbs-separator, & .notification-breadcrumb .MuiBreadcrumbs-li:last-of-type, &  .notification-breadcrumb .MuiBreadcrumbs-li:last-of-type + li.MuiBreadcrumbs-separator':
            {
              color: themeSettings.palette.text.secondary,
            },

          // entity dashboard link
          '&.list .notification-entity-dashboard-link, &.preview .notification-entity-dashboard-link': {
            display: 'none',
          },
        },
      },
    ],
  },

  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: 0,

        '&:last-child': {
          paddingBottom: 0,
        },

        '&.fill': {
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        },

        '&.scrollContent': {
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        },
      },
    },
  },

  MuiCardHeader: {
    styleOverrides: {
      root: {
        backgroundColor: themeSettings.palette.background.paper,
        padding: '4px 10px',
        borderBottom: `1px solid ${colors.lightDividerBlack}`,
      },

      title: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: 1.19,
        letterSpacing: 1,
        color: themeSettings.palette.text.primary,
        backgroundColor: themeSettings.palette.background.paper,
      },
    },
  },

  MuiCardActions: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
});
