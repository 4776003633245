import type {Theme} from '@mui/material';

import colors from '../colors';
const toolbarHeight = 90;

// todo need shading for new colors to work
// declare module '@mui/material/LinearProgress' {
//   interface LinearProgressPropsColorOverrides {
//     compliant: true;
//     inactive: true;
//     nonCompliant: true;
//   }
// }

/**
 * todo boxShadows have a theme setting
 *
 * Notes:
 *    avoid using colors const and hardcoded colors to allow changing themes
 *    color cannot be a css variable but otherwise try to use css variables
 *
 */
export const layoutTheme = (themeSettings: Theme): Theme['components'] => ({
  // useMediaQuery hook setting and not allow ssr usage of this hook for now
  MuiUseMediaQuery: {
    defaultProps: {
      noSsr: true,
    },
  },

  MuiAppBar: {
    styleOverrides: {
      root: {},

      colorPrimary: {
        backgroundColor: themeSettings.palette.background.paper,
      },
    },
  },

  MuiBreadcrumbs: {
    styleOverrides: {
      root: {
        fontSize: '14px',
        color: themeSettings.palette.text.secondary,

        '& .MuiBreadcrumbs-li:first-of-type, & .MuiBreadcrumbs-li:first-of-type + li.MuiBreadcrumbs-separator': {
          color: themeSettings.palette.text.disabled,
        },

        '& .MuiBreadcrumbs-li:last-of-type, & .MuiBreadcrumbs-li:last-of-type + li.MuiBreadcrumbs-separator': {
          color: themeSettings.palette.text.primary,
        },
      },
    },
  },

  MuiContainer: {
    styleOverrides: {
      root: {
        '&.formLayoutChildren': {
          margin: 'var(--spacing2)',
          padding: 0,
          width: 'auto',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          gap: 'var(--spacing2)',
          columnGap: 'var(--spacing6)',
          alignItems: 'flex-start',
        },

        '&.formLayoutChildren > *:not(.KDivider)': {
          width: 'var(--inputsWidth)',
        },

        '&.formLayoutChildren > * > *': {
          width: '100%',
        },

        '&.formLayoutChildren > .KDivider': {
          width: '100%',
        },

        '&.formLayoutChildren.growChildren': {
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          width: '100%',
        },

        '&.formLayoutChildren.growChildren > *:not(.KDivider)': {
          flexGrow: 1,
        },

        '&.appLayout': {
          margin: 0,
          padding: 0,
          maxWidth: 'unset',
          overflow: 'hidden',
          display: 'grid',
          gridTemplateColumns: 'fit-content(214px) auto 0',
          gridTemplateRows: '92px auto 0',
          gridTemplateAreas: `
                'navBar appBar appBar'
                'navBar main main'
                'navBar footer footer'
              `,
          gridGap: 0,
          width: '100vw',
          height: '100vh',
        },

        '&.appLayout > *': {
          position: 'relative',
        },

        '&.appLayout .main': {
          gridArea: 'main',
          overflow: 'auto',
          margin: 0,
          padding: 0,
        },

        // Adds a padding around the contents of a page within the nav menu and app bar
        '.gutters': {
          margin: 0,
          padding: `${themeSettings.spacing(3)} ${themeSettings.spacing(4)}`,
        },

        '&.appLayout .navBar': {
          gridArea: 'navBar',
          overflow: 'auto',
          backgroundColor: 'var(--menuBackgroundColor)',
          borderRight: '2px solid #f0f0f0',
          marginRight: '-2px',
        },

        '&.appLayout .appBar': {
          gridArea: 'appBar',
          zIndex: 1200,
          '.additional-app-bar-content': {
            color: themeSettings.palette.text.primary,
          },
        },

        '&.appLayout .footer': {
          borderTop: '1px solid #eaeaea',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
        },
      },
    },
  },

  MuiDrawer: {
    defaultProps: {
      BackdropProps: {style: {backgroundColor: 'rgba(0, 0, 0, 0.15)'}},
    },

    styleOverrides: {
      paper: {
        backgroundColor: colors.veryLightBlue,

        '&.panel': {
          top: 'var(--appBarHeight)',
          overflow: 'hidden',
        },
      },
    },
  },

  MuiToolbar: {
    styleOverrides: {
      regular: {
        height: toolbarHeight,

        gap: 20,

        '& .userMenuButton': {
          margin: '0',
          padding: '0 4px 0 0px',
        },
      },
    },
  },

  MuiLinearProgress: {
    styleOverrides: {
      root: {
        '& ~ .dot': {
          position: 'absolute',
          width: 18,
          height: 18,
          backgroundColor: colors.calcite,
          border: `solid 5px ${colors.dark}`,
          borderRadius: '50%',
          transition: 'all 0.4s linear',
          translate: '-50% -13px',
        },

        '& ~ .tick': {
          position: 'absolute',
          top: '12px',
          fontWeight: 'bold',
          backgroundColor: colors.calcite,
          translate: '-50% 0',
        },

        '& ~ .tick::before': {
          content: '""',
          color: colors.lightIconDefault,
          outline: `1px solid ${colors.lightIconDefault}`,
          display: 'block',
          position: 'absolute',
          left: '50%',
          top: '-18px',
          height: '18px',
          textAlign: 'center',
        },
      },
    },
  },

  MuiCircularProgress: {
    styleOverrides: {
      root: {
        '& ~ .dot': {
          position: 'absolute',
          width: 14,
          height: 14,
          backgroundColor: colors.calcite,
          border: `solid 2px ${colors.dark}`,
          borderRadius: '50%',
          transition: 'all 0.4s linear',
          translate: '-50% 0',
          bottom: 0,
          boxShadow: '0 2px 4px 0 #738c9a',
        },

        '& ~ .tickContainer': {
          position: 'absolute',
          transition: 'all 0.4s linear',
          width: 16,
          translate: '-50% 0',
          bottom: 5,
          outline: `1px solid rgba(24, 32, 38, 0.3)`,
        },

        '& ~ .tickContainer .tick': {
          position: 'absolute',
          translate: '-120% -50%',
          fontWeight: 'bold',
          backgroundColor: colors.calcite,
        },
      },
    },
  },
});
