import {Theme} from '@mui/material';
import colors from '../colors';

export const dataGridThemeCalendar = () =>
  ({
    // calendar
    '&.calendar .MuiDataGrid-cell, &.calendar MuiDataGrid-columnHeaderTitle': {
      fontSize: '12px',
    },

    // calendar row hover
    '&.calendar .MuiDataGrid-row:hover': {
      backgroundColor: colors.calcite,
    },

    // calendar cell hover
    '&.calendar .MuiDataGrid-cell:hover': {
      backgroundColor: 'rgba(245, 245, 245)',
    },

    // calendar month
    '&.calendar .MuiDataGrid-virtualScrollerRenderZone': {
      height: '100%',
    },

    // scrolling div width
    '&.calendar.hours .MuiDataGrid-virtualScrollerRenderZone': {
      width: 'auto',
    },

    // default height and border for all rows
    '&.calendar .MuiDataGrid-row': {
      width: '100%',
      maxHeight: 'none !important',
      minHeight: 'clamp(84px, 16.67%, 16.67%) !important',
      height: '16.67% !important',
      outline: `1px solid ${colors.lightDividerBlack}`,
    },

    '&.calendar.hours .MuiDataGrid-pinnedRows': {
      minHeight: '120px !important',
    },

    '&.calendar.hours .MuiDataGrid-row[data-id*="-1"]': {
      minHeight: '120px !important',
      maxHeight: '120px !important',
    },

    '&.calendar.schedule-config-view.hours .MuiDataGrid-pinnedRows': {
      minHeight: '140px !important',
      backgroundColor: colors.calcite,
    },

    // default height for all tags
    '&.calendar .MuiDataGrid-row .calendar-tag': {
      minHeight: 'clamp(14px, 17%, 32px)',
      maxHeight: 'clamp(14px, 17%, 32px)',
      height: 'clamp(14px, 17%, 32px)',
    },

    // PERIOD height
    '&.calendar .MuiDataGrid-row .calendar-tag.PERIOD': {
      minHeight: 'clamp(16px, 17%, 34px)',
      maxHeight: 'clamp(16px, 17%, 34px)',
      height: 'clamp(16px, 17%, 34px)',
    },

    '&.calendar .MuiDataGrid-row .calendar-tag.monthly-truncation': {
      maxHeight: 0,
      minHeight: 0,
      height: 0,
      display: 'none',
    },

    // month row or all day row leave gap for period if not present
    '&.calendar:not(.hours) .calendar-tag:first-of-type:not(.PERIOD), &.calendar.hours .MuiDataGrid-row[data-id="-1"] .calendar-tag:first-of-type:not(.PERIOD)':
      {
        marginTop: 'clamp(16px, 17%, 34px)',
      },

    // hours row not week or all-day
    '&.calendar.hours .MuiDataGrid-row:not([data-id*="-"])': {
      minHeight: 'clamp(58px, 13.33%, 13.33%) !important',
      height: '13.33% !important',
    },

    // hours row not week or all-day for schedule config view
    '&.calendar.hours.schedule-config-view .MuiDataGrid-row:not([data-id*="-"])': {
      minHeight: 'clamp(29px, 6.5%, 6.5%) !important',
      height: '6.5% !important',
    },

    // week row
    '&.calendar.hours.schedule-config-view .MuiDataGrid-row[data-id="-3"]': {
      minHeight: '22px !important',
      maxHeight: '22px !important',
      height: '22px !important',
    },

    // for stick row and header move to top
    '&.calendar.hours.schedule-config-view .MuiDataGrid-row.sticky, &.calendar.hours.schedule-config-view .MuiDataGrid-columnHeader.sticky':
      {
        zIndex: 30,
      },

    // defaults for all calendar cells
    '&.calendar .MuiDataGrid-cell': {
      position: 'relative',
      maxHeight: 'none !important',
      minHeight: '100%',
      padding: 0,
      alignItems: 'flex-start',
      flexDirection: 'column',
      gap: '1px',
      overflow: 'visible !important',
    },

    // calendar date of format '##' default settings
    '&.calendar .date': {
      position: 'absolute',
      top: -1,
      right: 6,
      width: 'clamp(16px, 1vw, 34px)',
      height: 'clamp(16px, 1vw, 34px)',
      lineHeight: 'clamp(16px, 1vw, 34px)',
      textAlign: 'center',
      fontSize: 'clamp(12px, 0.75vw, 24px)',
      fontWeight: 600,
      borderRadius: '50%',
      color: colors.calcite,
    },

    // calendar date of format '####' default settings
    '&.calendar.schedule-config-view.month .date': {
      width: 'auto',
    },

    // calendar date color when day is not within a period
    '&.calendar .date.noTags, &.calendar.month .calendar-tag:first-of-type:not(.PERIOD) ~ .date, &.calendar.month .calendar-tag.PERIOD.VOID ~ .date':
      {
        color: colors.dark,
      },

    // calendar date color when day is in previous month
    '&.calendar .date.previousMonth': {
      opacity: 0.3,
    },

    // todo next month date styling no change atm
    // calendar date color when day is in next month
    // '&.calendar .date.nextMonth': {
    //
    // },

    // calendar date color when day is now
    '&.calendar .date.today': {
      backgroundColor: colors.calcite,
      color: colors.dark,
    },

    // calendar today color in header or when day is not within a period
    '&.calendar .MuiDataGrid-columnHeader .today, &.calendar .date.today.noTags': {
      borderRadius: '50%',
      color: colors.calcite,
      backgroundColor: colors.dark,
      padding: '1px',
    },

    // calendar today in header defaults
    '&.calendar .MuiDataGrid-columnHeader .today': {
      display: 'inline-block',
      minWidth: 20,
      minHeight: 20,
      lineHeight: '18px',
      textAlign: 'center',
    },

    // calendar date is selected in picker
    // '&.calendar .date.selected': {
    // todo
    // backgroundColor: colors.calcite,
    // color: `${colors.dark} !important`,
    // },

    // calendar default tag styles
    '&.calendar .calendar-tag': {
      width: '100%',
      fontSize: 'clamp(10px, 0.4vw, 16px)',
      fontWeight: 600,
      margin: 0,
      borderRadius: 'clamp(3px, 0.2vw, 6px)',
    },

    '&.calendar div[data-mui-internal-clone-element]': {
      display: 'inline-block',
      width: '100%',
      minHeight: 'clamp(14px, 17%, 32px)',
      maxHeight: 'clamp(14px, 17%, 32px)',
      height: 'clamp(14px, 17%, 32px)',
    },

    '&.calendar div[data-mui-internal-clone-element] .calendar-tag': {
      marginTop: '0 !important',
    },

    // tag styling when not start of tag
    '&.calendar .calendar-tag:not(.start):not(.wrap-start)': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      // hide the right border of previous cell when multiple days
      // by growing the tag and moving it left to obscure it
      width: 'calc(100% + 1px)',
      maxWidth: 'calc(100% + 1px)',
      marginLeft: '-1px',
    },

    // tag styling when not end of tag
    '&.calendar .calendar-tag:not(.end):not(.wrap-end)': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },

    // tag when at end clip last 15%
    '&.calendar .calendar-tag.end:not(.PERIOD):not(.WEEK)': {
      width: '85%',
    },

    // tag PERIOD defaults
    '&.calendar .calendar-tag.PERIOD': {
      fontSize: 'clamp(10px, 0.4vw, 18px)',
      backgroundColor: colors.darkBlueGrey,
    },

    '& .participant-calendar-legend.PERIOD .MuiSvgIcon-root': {
      color: colors.darkBlueGrey,
    },

    // tag WEEK defaults
    '&.calendar .calendar-tag.WEEK': {
      fontSize: 'clamp(10px, 0.4vw, 18px)',
      backgroundColor: colors.lightBadges6DefaultMinimal,
      color: colors.dark,
      marginTop: '3px !important',
      marginBottom: '3px !important',
      textOverflow: 'unset !important',
      minHeight: '16px !important',
      maxHeight: '16px !important',
      height: '16px !important',
    },

    '&.calendar .calendar-tag.WEEK.start': {
      marginLeft: '1px !important',
      width: 'calc(100% - 1px) !important',
    },

    '&.calendar .calendar-tag.WEEK.end': {
      width: 'calc(100% - 1px) !important',
    },

    // tag VISIT defaults
    '&.calendar .calendar-tag.VISIT': {
      backgroundColor: colors.visit,
    },

    '& .participant-calendar-legend.VISIT .MuiSvgIcon-root': {
      color: colors.visit,
    },

    // tag DEVICE defaults
    '&.calendar .calendar-tag.DEVICE': {
      backgroundColor: colors.wear,
    },

    '& .participant-calendar-legend.DEVICE .MuiSvgIcon-root': {
      color: colors.wear,
    },

    // tag MOBILE defaults
    '&.calendar .calendar-tag.MOBILE': {
      backgroundColor: colors.task,
    },

    '& .participant-calendar-legend.MOBILE .MuiSvgIcon-root': {
      color: colors.task,
    },

    // https://github.com/KoneksaHealth/schedule-parser/blob/43ba7113225e0d03ae816b05e8db8062e4a46607/src/types/parser.ts#L194
    // ScheduledAssessmentStatus = 'COMPLETED' | 'MISSED' | 'OUTSTANDING'
    // | 'UPCOMING' | 'AVAILABLE' | 'BLOCKED' | 'RESCHEDULED' | 'RESCHEDULED_OUTSTANDING';

    // tag completed defaults
    '&.calendar .calendar-tag.COMPLETED': {
      backgroundColor: colors.lightBadges3Success,
    },

    '& .participant-calendar-legend.COMPLIANT .MuiSvgIcon-root': {
      color: colors.lightBadges3Success,
    },

    // tag missed defaults
    '&.calendar .calendar-tag.MISSED': {
      backgroundColor: colors.lightBadges5Danger,
    },
    '& .participant-calendar-legend.NON-COMPLIANT .MuiSvgIcon-root': {
      color: colors.lightBadges5Danger,
    },

    // tag OUTSTANDING defaults
    '&.calendar .calendar-tag.OUTSTANDING': {
      backgroundColor: colors.outstanding,
    },

    '& .participant-calendar-legend.OUTSTANDING .MuiSvgIcon-root': {
      color: colors.outstanding,
    },

    // tag RESCHEDULED_OUTSTANDING defaults
    '&.calendar .calendar-tag.RESCHEDULED_OUTSTANDING': {
      backgroundColor: colors.outstanding,
      opacity: '.5',
      textDecoration: 'line-through',
    },

    // tag RESCHEDULED defaults
    '&.calendar .calendar-tag.RESCHEDULED': {
      backgroundColor: colors.nonCompliant,
      opacity: '.5',
      textDecoration: 'line-through',
    },

    // tag RESCHEDULED but completed defaults
    '&.calendar .calendar-tag.RESCHEDULED_COMPLETED': {
      backgroundColor: colors.lightBadges3Success,
      opacity: '.5',
      textDecoration: 'line-through',
    },

    // tag UPCOMING defaults
    '&.calendar .MuiDataGrid-cell:not(.hoursTag) .calendar-tag.UPCOMING, &.calendar.hours .MuiDataGrid-row[data-id*="-"] .MuiDataGrid-cell.hoursTag .calendar-tag.UPCOMING':
      {
        opacity: 0.5,
      },

    // tag VOID defaults
    '&.calendar .calendar-tag.VOID': {
      opacity: 0,
    },

    // todo show voids on daily?
    // '&.calendar.day .calendar-tag.VOID': {
    //   display: 'none',
    // },

    '&.calendar .participant-calendar-legend-container': {
      height: 30,
      borderTop: '1px solid rgba(16, 22, 26, 0.15)',
      backgroundColor: 'rgba(216, 216, 216, 0.15)',
    },

    '& .participant-calendar-legend, & .participant-calendar-legend .MuiSvgIcon-root': {
      fontSize: 'clamp(10px, 0.4vw, 16px)',
    },

    '& .participant-calendar-legend .MuiListItemIcon-root': {
      minWidth: 'clamp(14px, 0.6vw, 24px)',
    },

    '&.calendar .MuiDataGrid-cell.hourlyLabels, &.calendar .MuiDataGrid-columnHeader.hourlyLabels': {
      fontSize: '12px',
      color: 'rgba(24, 32, 38, 0.5)',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      borderBottom: 'none',
      display: 'inline-block',
      padding: '5px',
      position: 'sticky',
      zIndex: 20,
    },

    '&.calendar .MuiDataGrid-cell.hourlyLabels *': {
      width: '100%',
      textAlign: 'right',
    },

    '&.calendar .MuiDataGrid-row:not([data-id*="-"]) .MuiDataGrid-cell.hoursTag': {
      display: 'block',
      overflow: 'visible',
    },

    '&.calendar .MuiDataGrid-row:not([data-id*="-"]) .MuiDataGrid-cell.hoursTag .calendar-tag': {
      position: 'absolute',
      left: 'calc(var(--leftPercent) * 1%)',
      right: 'calc(var(--rightPercent) * 1%)',
      width: 'calc(var(--widthPercent) * 1%)',
      alignItems: 'flex-start',
      maxHeight: 'none !important',
      outline: `2px solid ${colors.calcite}`,
      transition: 'all calc(var(--menu-transition-time) / 4)',
      overflow: 'hidden',
      opacity: 0.75,
    },

    '&.calendar .MuiDataGrid-row:not([data-id*="-"]) .MuiDataGrid-cell.hoursTag .calendar-tag.expanded': {
      zIndex: '100 !important',
      left: 0,
      right: 0,
      width: 'unset',
      opacity: 0.9,
    },

    '&.calendar .MuiDataGrid-row:not([data-id*="-"]) .MuiDataGrid-cell.hoursTag .MuiChip-label, &.calendar .MuiDataGrid-row:not([data-id*="-"]) .MuiDataGrid-cell.hoursTag .MuiChip-label *':
      {
        textOverflow: 'unset',
        height: '100%',
        minHeight: '100%',
      },

    // right panel
    '&.calendar .MuiDataGrid-cell.hourlyLabels:hover, &.calendar .MuiDataGrid-cell.right-panel:hover': {
      backgroundColor: colors.calcite,
    },

    '&.calendar .MuiDataGrid-row[data-id*="-"] .MuiDataGrid-cell.right-panel': {
      fontSize: 10,
      height: `calc(15 * 100%) !important`,
      position: 'relative',
      backgroundColor: colors.calcite,
    },

    '&.calendar .more': {
      fontSize: 10,
      color: colors.dark,
      padding: '2px 7px',
      fontStyle: 'italic',
      order: 10,
    },

    // tag hide label if not start
    '&.calendar:not(.day):not(.showAllLabels) .calendar-tag:not(.start):not(.wrap-start) .MuiChip-label': {
      opacity: 0,
    },

    '&.calendar:not(.day):not(.showAllLabels) .calendar-tag:not(.start):not(.wrap-start) .MuiChip-label:hover': {
      opacity: 0.75,
    },

    '&.calendar.schedule-config-view.month .MuiDataGrid-cell.vertical > span': {
      height: 'calc(100% - 6px)',
      borderRadius: '3px',
      backgroundColor: colors.lightBadges6DefaultMinimal,
    },

    '&.calendar.schedule-config-view.week .MuiDataGrid-cell:not(.hourlyLabels), &.calendar.schedule-config-view.week .MuiDataGrid-columnHeader:not(.hourlyLabels)':
      {
        minWidth: 'calc((100vw - var(--gutter)) / var(--countDaysToShow)) !important',
        maxWidth: 'calc((100vw - var(--gutter)) / var(--countDaysToShow)) !important',
        width: 'calc((100vw - var(--gutter)) / var(--countDaysToShow)) !important',
      },

    '&.calendar.schedule-config-view.week .MuiDataGrid-virtualScroller': {
      overflowX: 'auto !important',
    },

    '&.calendar.preview-calendar': {
      width: 150,
      minHeight: 'auto !important',
    },

    '&.calendar.preview-calendar .calendar-tag:first-of-type': {
      marginTop: '0 !important',
    },

    '&.calendar.preview-calendar .calendar-tag': {
      height: `20px !important`,
      minHeight: `20px !important`,
      maxHeight: `20px !important`,
    },

    '&.calendar.hours .time-marker': {
      position: 'relative',
      borderTop: `2px solid ${colors.now}`,
      top: 'calc(var(--dayPercentage) * 2400 * 1%)',
      zIndex: 1000,
      pointerEvents: false,
    },

    '&.calendar.hours .right-now::after': {
      backgroundColor: colors.now,
      borderRadius: '50%',
      content: '""',
      position: 'absolute',
      height: '12px',
      marginLeft: '-2px',
      marginTop: '-7px',
      width: '12px',
      left: 0,
    },
  }) satisfies NonNullable<NonNullable<NonNullable<Theme['components']>['MuiDataGrid']>['styleOverrides']>['root'];
