import AlbumIcon from '@mui/icons-material/Album';
import type {Theme} from '@mui/material';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';

/**
 * todo boxShadows have a theme setting
 *
 * Notes:
 *    avoid using colors const and hardcoded colors to allow changing themes
 *    color cannot be a css variable but otherwise try to use css variables
 *
 */
export const inputsTheme = (themeSettings: Theme): Theme['components'] => ({
  MuiCheckbox: {
    styleOverrides: {
      root: {
        /** todo UX color alias */
        color: 'rgba(206, 217, 224)',

        '&.Mui-disabled:not(.Mui-checked):not(.MuiCheckbox-indeterminate)': {
          /** todo UX color alias */
          color: 'rgba(206, 217, 224, 0.5)',
        },
      },
    },
  },

  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        whiteSpace: 'normal',

        '&.no-wrap': {
          whiteSpace: 'nowrap',
        },
      },
    },
  },

  MuiFormHelperText: {
    styleOverrides: {
      root: {
        display: 'flex',
        flexDirection: 'row',
        gap: themeSettings.spacing(1),
        alignItems: 'center',
        minHeight: themeSettings.spacing(3),
        fontSize: 14,
        verticalAlign: 'middle',
        marginTop: themeSettings.spacing(1),
        textDecoration: 'none',
        color: themeSettings.palette.text.secondary,
      },
    },
  },

  MuiInputBase: {
    defaultProps: {
      color: 'secondary',
    },

    styleOverrides: {
      root: {
        lineHeight: 1.25,
      },
    },
  },

  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: 14,
        lineHeight: 1.29,
        marginBottom: 5,

        '& .MuiInputLabel-asterisk': {
          color: themeSettings.palette.error.main,
        },
      },
    },
  },

  MuiOutlinedInput: {
    variants: [
      {
        props: {disabled: true},
        style: {
          boxShadow: 'none !important',

          '&:not(.MuiSelect-root)': {
            backgroundColor: 'rgba(206, 217, 224, 0.5)',
          },
        },
      },
      {
        props: {color: 'secondary'},
        style: {
          boxShadow: 'inset 0 0 0 1px var(--lightDividerBlack)',
        },
      },
      {
        props: {color: 'primary'},
        style: {
          boxShadow: 'inset 0 0 0 1px #137cbd',
        },
      },
      {
        props: {color: 'success'},
        style: {
          boxShadow: 'inset 0 0 0 1px var(--lightBadges3Success)',
        },
      },
      {
        props: {color: 'warning'},
        style: {
          boxShadow: 'inset 0 0 0 1px var(--lightBadges4Warning)',
        },
      },
      {
        props: {color: 'error'},
        style: {
          boxShadow: 'inset 0 0 0 1px var(--lightBadges5Danger)',
        },
      },
    ],

    styleOverrides: {
      root: {
        padding: '10px',
        backgroundColor: themeSettings.palette.background.paper,
        minHeight: 40,
        maxHeight: 40,
        height: 40,

        '&.minimal': {
          boxShadow: 'none',
        },
      },

      input: {
        lineHeight: 1.25,
        minHeight: 20,
        maxHeight: 20,
        height: 20,
        padding: 0,
      },

      notchedOutline: {
        border: 'none',
      },
    },
  },

  MuiRadio: {
    defaultProps: {
      /** todo replace with fontawesome icon */
      checkedIcon: <AlbumIcon />,
    },

    styleOverrides: {
      root: {
        color: 'rgba(206, 217, 224, 0.5)',

        '&.Mui-disabled:not(.Mui-checked):not(.MuiCheckbox-indeterminate)': {
          color: 'rgba(206, 217, 224, 0.5)',
        },
      },
    },
  },

  MuiNativeSelect: {},

  MuiAutocomplete: {
    defaultProps: {
      color: 'secondary',
    },

    styleOverrides: {
      popupIndicator: {
        // Makes autocomplete dropdown carrot look the same as MuiSelect icon
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
  },

  MuiSelect: {
    defaultProps: {
      color: 'secondary',
    },

    styleOverrides: {
      select: {
        '&.Mui-disabled': {
          boxShadow: 'none',
        },

        '&.tags': {
          maxHeight: 'none',
          paddingRight: '22px',
        },

        '&.noOpenMenu': {
          cursor: 'default',
        },

        '&.noOpenMenu ~ .MuiSvgIcon-root': {
          display: 'none',
        },
      },
    },
  },

  MuiSwitch: {
    styleOverrides: {
      root: {
        margin: 10,
        padding: 0,
        width: 32,
        height: 20,
      },

      input: {
        width: 32,
      },

      switchBase: {
        padding: 2,

        '&.Mui-checked': {
          transform: 'translateX(12px)',
        },

        '&.Mui-checked+.MuiSwitch-track': {
          opacity: 1,
        },

        '&.Mui-disabled+.MuiSwitch-track': {
          opacity: 0.5,
        },
      },

      thumb: {
        padding: 0,
        width: 16,
        height: 16,
        boxShadow: '0 1px 2px 0 rgba(16, 22, 26, 0.2), 0 0 0 1px rgba(16, 22, 26, 0.1)',
        backgroundColor: themeSettings.palette.background.paper,
      },

      track: {
        width: 32,
        height: 20,
        borderRadius: 10,
        opacity: 1,
        /** todo UX color alias */
        backgroundColor: 'rgba(167, 182, 194, 0.5)',
        transition: themeSettings.transitions.create(['background-color'], {
          duration: 500,
        }),
      },
    },
  },

  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontSize: 14,
        lineHeight: 1.29,
        marginBottom: 5,

        '& .MuiFormLabel-asterisk': {
          color: themeSettings.palette.error.main,
        },
        '&.Mui-focused': {
          color: 'inherit',
        },
      },
    },
  },
});
