import type {Theme} from '@mui/material';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import colors from '../colors';

/**
 * todo boxShadows have a theme setting
 *
 * Notes:
 *    avoid using colors const and hardcoded colors to allow changing themes
 *    color cannot be a css variable but otherwise try to use css variables
 *
 */
export const menuTheme = (themeSettings: Theme): Theme['components'] => ({
  MuiMenuItem: {
    styleOverrides: {
      root: {
        padding: themeSettings.spacing(2),
        lineHeight: 1.25,
        transition: 'all 0.5s ease-in-out',

        '& > .ref-wrapper, & > .ref-wrapper > .MuiMenuItem-root, & > .referencable, & > .referencable > .ref-wrapper, & > .referencable > .ref-wrapper > .MuiMenuItem-root':
          {
            padding: 0,
            height: '100%',
            width: '100%',
          },

        '&:hover, &.hover': {
          backgroundColor: colors.veryLightBlue,
        },

        '& .MuiTypography-root': {
          lineHeight: 1.25,
        },

        '& .MuiCheckbox-root': {
          height: 20,
        },

        '&.Mui-disabled': {
          opacity: 0.5,
        },

        '& .MuiLink-root, & .MuiLink-root:hover': {
          width: '100%',
          textDecoration: 'none',
        },

        '&.noIconWidth .MuiListItemIcon-root': {
          minWidth: 'auto',
        },

        '&.noHover:hover': {
          cursor: 'default',
          backgroundColor: 'inherit',
        },

        '&.rawIcon .MuiListItemIcon-root': {
          paddingLeft: 12,
        },

        '&.collapseAble .expand': {
          rotate: '0deg',
          transition: 'all 0.5s ease-in-out',
        },

        '&.collapseAble .collapse': {
          rotate: '90deg',
          transition: 'all 0.5s ease-in-out',
        },

        '&.collapse': {
          marginBottom: '-5px',
          marginTop: '-5px',
          maxHeight: 0,
          paddingBottom: 0,
          paddingTop: 0,
          overflow: 'hidden',
        },
      },
    },
  },

  MuiMenu: {
    styleOverrides: {
      paper: {
        '&::-webkit-scrollbar': {
          WebkitApperarance: 'none',
          width: '10px',
        },

        '&::-webkit-scrollbar-thumb': {
          borderRadius: '5px',
          backgroundColor: 'rgba(0,0,0,.5)',
          WebkitBoxShadow: '0 0 1px rgba(255,255,255,.5)',
        },
      },
    },
  },

  MuiList: {
    styleOverrides: {
      root: {
        '&[role=listbox]': {
          maxHeight: '300px',
        },

        '&[role=menu]': {
          padding: `${themeSettings.spacing(1)}`,
          backgroundColor: themeSettings.palette.background.paper,
          boxShadow:
            '0 8px 24px 0 rgba(16, 22, 26, 0.2), 0 2px 4px 0 rgba(16, 22, 26, 0.2), 0 0 0 1px rgba(16, 22, 26, 0.1)',

          '& .MuiListSubheader-root': {
            color: themeSettings.palette.text.primary,
            fontSize: 16,
            fontWeight: 600,
            lineHeight: '40px',
            height: 40,
            paddingLeft: 10,
          },

          '&.flat': {
            boxShadow: 'none',
          },

          '&.row': {
            display: 'flex',
          },

          /** margin added to so border does not overlap hover */
          '&.row .MuiMenuItem-root:not(:first-of-type)': {
            marginLeft: '1px',
          },

          '&.row .MuiMenuItem-root:not(:first-of-type)::after': {
            position: 'absolute',
            left: -1,
            height: 20,
            borderRight: '1px solid',
            borderRightColor: 'var(--menuItemBorderColor, var(--menuItemColor))',
            content: '""',
          },

          '& .MuiTypography-root': {
            color: 'var(--menuItemColor)',
          },

          '&.tabs': {
            justifyContent: 'center',
          },

          '&.tabs .MuiMenuItem-root': {
            borderBottom: 'solid 3px var(--lightDividerBlack)',
            flexGrow: 1,
            textAlign: 'center',
            fontWeight: 600,
            color: 'rgba(24, 32, 38, 0.3)',
          },

          '&.tabs .MuiMenuItem-root.active, &.tabs .MuiMenuItem-root.hover, &.tabs .MuiMenuItem-root:hover': {
            borderBottom: 'solid 3px #00989e',
            color: themeSettings.palette.text.primary,
          },

          '&.tabs .MuiMenuItem-root.hover, &.tabs .MuiMenuItem-root:hover': {
            backgroundColor: 'rgb(138, 199, 203, 0.3)',
          },

          '&.navBarMenu': {
            backgroundColor: 'transparent',
            padding: 0,
            boxShadow: 'none',
            color: colors.calcite,
          },

          '&.navBarMenu .MuiMenuItem-root': {
            color: colors.calcite,
            borderRadius: '2em',
            overflow: 'hidden',
            minWidth: 'calc(var(--menu-width) - 16px)',
            width: 'calc(var(--menu-width) - 16px)',
            maxWidth: 'calc(var(--menu-width) - 16px)',
            height: 36,
            margin: '10px 8px',
            padding: '5px 15px',
            gap: themeSettings.spacing(2),
          },

          '&.navBarMenu .MuiTypography-root': {
            color: 'var(--menu-text-color-collapseable)',
            transition: 'var(--menu-transition)',
            fontSize: 14,
          },

          '&.navBarMenu .MuiListItemIcon-root': {
            color: colors.calcite,
          },

          '&.navBarMenu .MuiMenuItem-root:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
          },
        },
        '&.condensed-list': {
          paddingTop: '0',
          paddingBottom: '0',
        },
      },
    },
  },

  MuiListSubheader: {
    styleOverrides: {
      root: {
        padding: themeSettings.spacing(2),
        lineHeight: '20px',
        transition: 'all 0.5s ease-in-out',
        fontSize: '16px',
        fontWeight: 600,
        color: colors.dark,
      },
    },
  },

  MuiTab: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          color: themeSettings.palette.primary.contrastText,
          backgroundColor: themeSettings.palette.primary.main,
        },

        '&:not(.Mui-selected)': {
          /** todo UX color alias */
          backgroundColor: '#edf0f2',
          color: colors.dark,
        },

        '&.Mui-disabled': {
          opacity: 0.5,
        },
      },

      selected: {},
    },
  },

  MuiListItem: {
    styleOverrides: {
      root: {
        '&.striped:nth-of-type(odd)': {
          backgroundColor: colors.lightTableRowOdd,
        },
        '&.condensed': {
          paddingTop: '0',
          paddingBottom: '0',
        },
        '&.condensed p': {
          margin: '0',
        },
      },
    },
  },

  MuiListItemButton: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          color: themeSettings.palette.primary.contrastText,
          backgroundColor: themeSettings.palette.primary.main,
        },
      },
    },
  },
});
