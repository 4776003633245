import type {Theme} from '@mui/material';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import colors from '../colors';

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    plumAccent: true;
    tealAccent: true;
    darkBlueAccent: true;
    group: true;
    grouped: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    hoveringToolbar: true;
  }
}
/**

 /**
 * todo boxShadows have a theme setting
 *
 * Notes:
 *    avoid using colors const and hardcoded colors to allow changing themes
 *    color cannot be a css variable but otherwise try to use css variables
 *
 */
export const elementsTheme = (themeSettings: Theme): Theme['components'] => ({
  MuiButton: {
    defaultProps: {
      variant: 'contained',
      color: 'secondary',
    },

    variants: [
      {
        props: {variant: 'hoveringToolbar'},
        style: {
          color: themeSettings.palette.text.primary,
          minWidth: 'unset',
          height: '36px',
          width: '36px',
          padding: '8px',
          border: 'none',
          boxShadow: 'unset',
          transition: 'unset',
          '&:hover, &.hover': {
            boxShadow: 'unset',
            background: colors.veryLightBlue,
          },
          '&:focus, &.active': {
            boxShadow: 'unset',
            border: 'none',
            background: colors.veryLightBlue,
          },
        },
      },
    ],

    styleOverrides: {
      contained: {
        padding: '6px 10px',
        borderRadius: '3px',
        boxShadow: 'inset 0 0 0 1px rgba(16, 22, 26, 0.4)',
        fontWeight: 'bold',
        '&:hover, &.hover': {
          boxShadow: 'inset 0 0 0 1px rgba(16, 22, 26, 0.4)',
        },

        '&:focus, &.active': {
          boxShadow: 'inset 0 1px 2px 0 rgba(16, 22, 26, 0.2), inset 0 0 0 1px rgba(16, 22, 26, 0.2)',
        },

        '&.MuiButton-containedSuccess:hover, &.MuiButton-containedSuccess.hover': {
          opacity: 0.8,
        },

        '&.MuiButton-containedSuccess.Mui-disabled': {
          backgroundColor: themeSettings.palette.success.main,
          color: themeSettings.palette.success.contrastText,
          opacity: 0.4,
        },

        '&.MuiButton-containedWarning:hover, &.MuiButton-containedWarning.hover': {
          opacity: 0.8,
        },

        '&.MuiButton-containedWarning.Mui-disabled': {
          backgroundColor: themeSettings.palette.warning.main,
          color: themeSettings.palette.warning.contrastText,
          opacity: 0.4,
        },

        '&.MuiButton-containedError:hover, &.MuiButton-containedError.hover': {
          opacity: 0.8,
        },

        '&.MuiButton-containedError.Mui-disabled': {
          backgroundColor: themeSettings.palette.error.main,
          color: themeSettings.palette.error.contrastText,
          opacity: 0.4,
        },
      },

      containedPrimary: {
        color: themeSettings.palette.primary.contrastText,
        backgroundImage:
          'linear-gradient(to top, rgba(255, 255, 255, 0) 114%, var(--lightIconWhite) 14%), linear-gradient(to bottom, var(--cobalt), var(--cobalt))',

        '&:hover, &.hover': {
          backgroundImage:
            'linear-gradient(to top, rgba(255, 255, 255, 0) 114%, var(--calcite) 14%), linear-gradient(to bottom, var(--frenchBlue), var(--frenchBlue))',
        },

        '&:focus, &.active': {
          backgroundImage: 'none',
          boxShadow: 'inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px 0 rgba(16, 22, 26, 0.2)',
          backgroundColor: themeSettings.palette.primary.main,
        },

        '&.Mui-disabled': {
          color: themeSettings.palette.primary.contrastText,
          boxShadow: 'none',
          backgroundImage: 'none',
          /** todo UX color alias */
          backgroundColor: colors.cobaltDisabled,
        },
      },

      containedSecondary: {
        color: themeSettings.palette.text.primary,
        fontWeight: 'normal',
        boxShadow: 'inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 1px 0 rgba(16, 22, 26, 0.1)',
        backgroundImage:
          'linear-gradient(to bottom, var(--calcite), rgba(255, 255, 255, 0)), linear-gradient(to bottom, var(--lightTableRowEven), var(--lightTableRowEven))',

        '&:hover, &.hover': {
          boxShadow: 'inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 1px 0 rgba(16, 22, 26, 0.1)',
          backgroundImage:
            'linear-gradient(to bottom, var(--calcite), rgba(255, 255, 255, 0)), linear-gradient(to bottom, var(--lightDatePickerRange), var(--lightDatePickerRange))',
        },

        '&:focus, &.active': {
          backgroundImage: 'none',
          boxShadow: 'inset 0 1px 2px 0 rgba(16, 22, 26, 0.2), inset 0 0 0 1px rgba(16, 22, 26, 0.2)',
          /** todo UX color alias */
          backgroundColor: '#d8e1e8',
        },

        '&.Mui-disabled': {
          backgroundImage: 'none',
          backgroundColor: 'rgba(206, 217, 224, 0.5)',
          /** todo UX color alias */
          color: 'rgba(92, 112, 128, 0.6)',
        },
      },

      outlined: {
        borderColor: 'transparent',

        '&.Mui-disabled': {
          borderColor: 'transparent',
          opacity: 0.4,
        },

        '&:focus, &.active': {
          borderColor: 'inherit',
        },

        '&:hover, &--hover': {
          color: themeSettings.palette.background.paper,
        },

        '&.MuiButton-outlinedPrimary': {
          backgroundColor: themeSettings.palette.primary.light,
          color: themeSettings.palette.primary.main,
        },

        '&.MuiButton-outlinedPrimary:hover, &.MuiButton-outlinedPrimary.hover': {
          backgroundImage:
            'linear-gradient(to top, rgba(255, 255, 255, 0) 114%, var(--calcite) 14%), linear-gradient(to bottom, var(--frenchBlue), var(--frenchBlue))',
          color: themeSettings.palette.primary.contrastText,
        },

        '&.MuiButton-outlinedSecondary': {
          backgroundColor: themeSettings.palette.secondary.light,
          color: themeSettings.palette.secondary.main,
        },

        '&.MuiButton-outlinedSecondary:hover, &.MuiButton-outlinedSecondary.hover': {
          backgroundColor: themeSettings.palette.secondary.main,
          color: themeSettings.palette.secondary.contrastText,
        },

        '&.MuiButton-outlinedSuccess': {
          backgroundColor: themeSettings.palette.success.light,
          color: themeSettings.palette.success.main,
        },

        '&.MuiButton-outlinedSuccess:hover, &.MuiButton-outlinedSuccess.hover': {
          backgroundColor: themeSettings.palette.success.main,
          color: themeSettings.palette.success.contrastText,
        },

        '&.MuiButton-outlinedWarning': {
          backgroundColor: themeSettings.palette.warning.light,
          color: themeSettings.palette.warning.main,
        },

        '&.MuiButton-outlinedWarning:hover, &.MuiButton-outlinedWarning.hover': {
          backgroundColor: themeSettings.palette.warning.main,
          color: themeSettings.palette.warning.contrastText,
        },

        '&.MuiButton-outlinedError': {
          backgroundColor: themeSettings.palette.error.light,
          color: themeSettings.palette.error.main,
        },

        '&.MuiButton-outlinedError:hover, &.MuiButton-outlinedError.hover': {
          backgroundColor: themeSettings.palette.error.main,
          color: themeSettings.palette.error.contrastText,
        },

        '&.pagination': {
          minWidth: 'auto',
          width: '30px',
          height: '30px',
          borderColor: colors.lightDividerBlack,
          backgroundColor: colors.lightTableRowEven,
        },
      },
    },
  },

  MuiButtonBase: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },

  MuiButtonGroup: {
    styleOverrides: {
      root: {
        '&.small-row': {
          display: 'flex',
          direction: 'row',
          justifyContent: 'space-between',
        },
        '&.small-row >  button': {
          minWidth: '34px',
          flex: '1 1 auto',
          height: '28px',
        },
        '&.small-row > button.non-selected': {
          backgroundColor: colors.calcite,
        },
      },
      groupedOutlined: {
        boxShadow: 'inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 1px 0 rgba(16, 22, 26, 0.2)',
        borderRight: 'none',
      },
    },
  },

  MuiChip: {
    styleOverrides: {
      root: {
        maxHeight: themeSettings.spacing(5),
        borderRadius: themeSettings.shape.borderRadius,
        margin: `${themeSettings.spacing(1)} 0`,

        '& .MuiChip-label': {
          flexGrow: 1,
        },

        '& .MuiChip-deleteIcon': {
          display: 'flex',
          alignItems: 'center',
        },

        '&:not(.no-hover).MuiChip-filledSuccess:hover': {
          backgroundColor: themeSettings.palette.success.light,
          color: themeSettings.palette.success.main,

          '& .MuiChip-deleteIcon': {
            color: themeSettings.palette.success.main,
          },
        },

        '&.MuiChip-outlinedSuccess': {
          backgroundColor: themeSettings.palette.success.light,
        },

        '&:not(.no-hover).MuiChip-outlinedSuccess:hover': {
          backgroundColor: themeSettings.palette.success.main,
        },

        '&:not(.no-hover).MuiChip-filledWarning:hover': {
          backgroundColor: themeSettings.palette.warning.light,
          color: themeSettings.palette.warning.main,

          '& .MuiChip-deleteIcon': {
            color: themeSettings.palette.warning.main,
          },
        },

        '&.MuiChip-outlinedWarning': {
          backgroundColor: themeSettings.palette.warning.light,
        },

        '&:not(.no-hover).MuiChip-outlinedWarning:hover': {
          backgroundColor: themeSettings.palette.warning.main,
        },

        '&:not(.no-hover).MuiChip-filledError:hover': {
          backgroundColor: themeSettings.palette.error.light,
          color: themeSettings.palette.error.main,

          '& .MuiChip-deleteIcon': {
            color: themeSettings.palette.error.main,
          },
        },

        '&.MuiChip-outlinedError': {
          backgroundColor: themeSettings.palette.error.light,
          color: colors.lightIconDangerDarker,

          '& .MuiChip-deleteIcon': {
            color: colors.lightIconDangerDarker,
          },
        },

        '&:not(.no-hover).MuiChip-outlinedError:hover': {
          backgroundColor: themeSettings.palette.error.main,
        },

        '& > svg': {
          paddingLeft: '2px',
        },

        '& .totalBadge': {
          position: 'absolute',
          left: '-8px',
          top: '-8px',
          backgroundColor: colors.darkBlueGrey,
          borderRadius: '8px',
          padding: '0 0.5em',
          height: '16px',
          lineHeight: '16px',
          textAlign: 'center',
          fontSize: '10px',
          fontWeight: 600,
          color: colors.lightTableRowEven,
        },

        '& .totalBadge.grouped': {
          left: '-8px',
          backgroundColor: colors.darkBlueGreyAlpha50,
        },

        '&.grouped': {
          marginTop: '-5px',
        },

        '&.grouped::after': {
          content: '""',
          border: '0 solid #008ebc',
          borderLeftWidth: '1px',
          borderBottomWidth: '1px',
          position: 'absolute',
          left: '-20px',
          top: '-25px',
          width: '20px',
          height: '40px',
        },

        '&.pain-marker': {
          width: '20px',
          height: '20px',
          borderRadius: '50%',
          backgroundColor: colors.error,
          boxShadow: '0px 0px 0px 3px #ec7979 inset',
          border: '3px solid #f9d2d2',
          zIndex: 15,
        },
      },

      outlined: {
        borderColor: 'transparent',

        '&:not(.no-hover):hover': {
          color: themeSettings.palette.primary.contrastText,
        },

        '&:not(.no-hover):hover .MuiChip-deleteIcon': {
          color: themeSettings.palette.primary.contrastText,
        },
      },

      colorPrimary: {
        '&:not(.no-hover):hover': {
          backgroundColor: themeSettings.palette.primary.light,
          color: themeSettings.palette.primary.main,

          '& .MuiChip-deleteIcon': {
            color: themeSettings.palette.primary.main,
          },
        },

        '&.MuiChip-outlinedPrimary': {
          backgroundColor: themeSettings.palette.primary.light,
        },

        '&:not(.no-hover).MuiChip-outlinedPrimary:hover': {
          backgroundColor: themeSettings.palette.primary.main,
        },
      },

      colorSecondary: {
        '&:not(.no-hover):hover': {
          backgroundColor: themeSettings.palette.secondary.light,
          color: themeSettings.palette.secondary.main,

          '& .MuiChip-deleteIcon': {
            color: themeSettings.palette.secondary.main,
          },
        },

        '&.MuiChip-outlinedSecondary': {
          backgroundColor: themeSettings.palette.secondary.light,
        },

        '&:not(.no-hover).MuiChip-outlinedSecondary:hover': {
          backgroundColor: themeSettings.palette.secondary.main,
        },
      },
    },
  },

  MuiIconButton: {
    styleOverrides: {
      root: {
        color: themeSettings.palette.text.primary,
      },
    },
  },

  MuiSlider: {
    styleOverrides: {
      root: {
        '&.extend-rail': {
          width: '90% !important',
          margin: 'auto auto',
        },
        '&.extend-rail-pristine': {
          width: '90% !important',
          margin: 'auto auto',
          borderRadius: 'none',
        },
        '&.extend-rail-pristine::before, &.extend-rail-pristine::after': {
          backgroundColor: colors.linerProgressTrack,
          content: '""',
          width: '40px',
          height: '4px',
          display: 'inline',
          position: 'absolute',
          left: '-40px',
        },
        '&.extend-rail-pristine::after': {
          left: 'unset',
          right: '-40px',
        },
        '&.extend-rail::before, &.extend-rail::after': {
          backgroundColor: colors.linerProgressTrack,
          content: '""',
          width: '40px',
          height: '4px',
          display: 'inline',
          position: 'absolute',
          left: '-40px',
        },
        '&.extend-rail::before': {
          top: '13px',
        },
        '&.extend-rail::after': {
          left: 'unset',
          right: '-40px',
        },
      },
      rail: {
        backgroundColor: colors.linerProgressTrack,
        opacity: 'unset',
        '&.hidden': {
          display: 'none',
        },
      },
      track: {
        '&.hidden': {
          display: 'none',
        },
      },
      thumb: {
        zIndex: 2,
      },
      mark: {
        zIndex: 2,
        backgroundColor: colors.lightDividerBlack,
        '&.extended-mark': {
          height: '10px',
        },
        '&.no-mark': {
          height: '0',
          backgroundColor: 'transparent',
        },
      },
    },
  },

  MuiTypography: {
    styleOverrides: {
      root: ({ownerState}) => ({
        ...(ownerState.color === 'success' && {
          color: colors.success,
        }),

        ...(ownerState.color === 'warning' && {
          color: colors.warning,
        }),

        ...(ownerState.color === 'compliant' && {
          color: colors.compliant,
        }),

        ...(ownerState.color === 'nonCompliant' && {
          color: colors.nonCompliant,
        }),

        ...(ownerState.color === 'inactive' && {
          color: colors.inactive,
        }),

        '&.accountHeader': {
          fontFamily: 'Lato Thin, sans-serif',
          fontSize: '28px',
          lineHeight: 'normal',
        },

        '&.stepList > p': {
          margin: '0',
        },

        '&.escaped-html > p': {
          margin: '0',
        },
      }),
    },
  },
});
