import {Theme} from '@mui/material';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import colors from '../colors';
import {Font} from '../theme';
import {dataGridThemeCalendar} from './calendar.theme';

/**
 * todo boxShadows have a theme setting
 *
 */
export const dataGridTheme = (themeSettings: Theme): Theme['components'] => ({
  MuiDataGrid: {
    styleOverrides: {
      footerContainer: {
        marginBottom: '-10px',
      },

      main: {
        border: `1px solid var(--lightDividerBlack)`,
        backgroundColor: 'var(--lightIconWhite)',
      },

      root: {
        fontFamily: Font.medium,
        minHeight: 160,
        border: `none`,

        '&.no-border .MuiDataGrid-main, &.no-border .MuiDataGrid-row, &.no-border-cells .MuiDataGrid-cell': {
          borderLeft: 'none !important',
          borderTop: 'none !important',
          borderBottom: 'none !important',
          borderRight: 'none !important',
          outline: 'none !important',
        },

        /** headers */
        '& .MuiDataGrid-columnsContainer': {
          backgroundColor: 'var(--lightIconWhite)',
        },

        '& .MuiDataGrid-iconSeparator': {
          display: 'none',
        },

        '& .MuiDataGrid-columnHeader': {
          padding: '0 5px',
          borderRight: '1px solid var(--lightDividerBlack)',
        },

        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: 600,
        },

        '& .MuiDataGrid-columnHeaderTitleContainer': {
          padding: '0 5px',
        },

        '& .MuiDataGrid-pinnedRows': {
          boxShadow: 'none',
        },

        /** rows */
        '& .MuiDataGrid-row': {
          backgroundColor: 'var(--lightTableRowEven)',
        },

        '&:not(.no-stripes) .MuiDataGrid-row:nth-of-type(odd)': {
          backgroundColor: 'var(--lightTableRowOdd)',
        },

        '& .MuiDataGrid-row:not(.bottomBorder) .MuiDataGrid-cell': {
          borderBottomWidth: 0,
        },

        '& .MuiDataGrid-row.sticky': {
          position: 'sticky',
          top: 0,
          zIndex: 2,
          backgroundColor: 'var(--calcite)',
        },

        '& .MuiDataGrid-row.collapsable, & .MuiDataGrid-row.collapsable .tagged, & .MuiDataGrid-row.collapsable .totalBadge, & .MuiDataGrid-row.collapsable .grouped::after':
          {
            // todo testing disabling animation
            // transition: 'all 1s linear',
          },

        '& .MuiDataGrid-row.collapsable': {
          height: '40px !important',
        },

        '& .MuiDataGrid-row.collapsable.collapse': {
          height: '0 !important',
          minHeight: '0 !important',
          opacity: 0,
        },

        '& .MuiDataGrid-row.study-periods .MuiChip-root': {
          backgroundColor: 'var(--darkBlueGrey)',
          color: colors.lightTableRowEven,
          fontSize: '14px',
          fontWeight: 600,
        },

        '& .MuiDataGrid-row.study-periods .MuiChip-root:hover': {
          opacity: 0.75,
        },

        /** todo demo */
        '& .MuiDataGrid-row.sticky:nth-of-type(1)': {
          zIndex: 20,
        },

        '& .MuiDataGrid-row.sticky:nth-of-type(2)': {
          top: '1.5em',
          zIndex: 19,
        },

        /** todo demo */
        '& .MuiDataGrid-row.sticky:nth-of-type(3)': {
          top: '3.25em',
          zIndex: 18,
        },

        /** todo demo */
        '& .MuiDataGrid-row.sticky:nth-of-type(4)': {
          top: '4.5em',
        },

        /** cell */
        '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
          borderBottomWidth: 0,
          outline: 'none',
        },

        '& .MuiDataGrid-cell.center': {
          justifyContent: 'center',
        },

        '& .MuiDataGrid-cell.fill': {
          position: 'relative',
          overflow: 'visible !important',
          padding: '1px',
        },

        '& .MuiDataGrid-cell.no-border': {
          borderTop: 'none',
        },

        '& .MuiDataGrid-cell.sticky': {
          position: 'sticky',
          left: 0,
          zIndex: 1,
          backgroundColor: 'var(--calcite)',
        },

        '& .MuiDataGrid-cell.wrap': {
          whiteSpace: 'normal !important',
          wordWrap: 'break-word !important',
        },

        '& .MuiDataGrid-cell:not(.wrap) .MuiTypography-root': {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },

        /** VTODO */
        '& .MuiDataGrid-row:hover .MuiDataGrid-cell.sticky': {
          backgroundColor: 'rgba(245, 245, 245)',
        },

        '& .MuiDataGrid-cell.tagged div': {
          height: themeSettings.spacing(4),
          maxHeight: themeSettings.spacing(4),
        },

        /**
         * left is (start - dayStart) * dayWidth [1 - -10 * 50 = 550]
         * minWidth is the count of days * dayWidth
         */
        '& .MuiDataGrid-cell.tagged .MuiChip-root': {
          position: 'absolute',
          left: `calc(((var(--start) - var(--dayStart)) * var(--dayWidth) + 2) * 1px)`,
          minWidth: `calc((var(--count) * var(--dayWidth) - 3) * 1px)`,
          maxWidth: 'calc((var(--count) * var(--dayWidth) - 3) * 1px)',
        },

        '& .MuiDataGrid-cell .days': {
          textAlign: 'center',
          display: 'inline-block',
          margin: 'auto 0',
          minWidth: 'calc(var(--dayWidth) * 1px)',
          maxWidth: 'calc(var(--dayWidth) * 1px)',
          borderTop: '1px solid var(--lightDividerBlack)',
          borderRight: '1px solid var(--lightDividerBlack)',
          maxHeight: '23px !important',
          position: 'relative',
        },

        '& .MuiDataGrid-cell.formatted-cell-text': {
          '&.success': {color: 'var(--lightIconSuccess)'},
          '&.error': {color: 'var(--lightIconDanger)'},
          '&.warning': {color: 'var(--lightBadges4Warning)'},
          '&.info': {color: 'var(--frenchBlue)'},
        },

        /** todo cell borders */
        // '& .MuiDataGrid-cell .days::after': {
        //   content: '""',
        //   display: 'block',
        //   position: 'absolute',
        //   top: '23px',
        //   margin: 'auto 0',
        //   minWidth: 'calc(var(--dayWidth) * 1px)',
        //   maxWidth: 'calc(var(--dayWidth) * 1px)',
        //   borderRight: '1px solid var(--lightDividerBlack)',
        //   height: '646px',
        //   pointerEvents: 'none',
        // },

        /** todo show tooltip on hover when showing ellipsis */
        // '& .MuiDataGrid-cell:hover': {
        //   textOverflow: 'clip',
        //   whiteSpace: 'normal',
        //   lineHeight: '1 !important',
        //   wordBreak: 'break-all',
        //   overflow: 'visible',
        // },

        '& .MuiDataGrid-row.selected': {
          backgroundColor: 'var(--cobaltLight)',
        },

        /** todo show tooltip on hover when showing ellipsis */
        // '& .MuiTypography-root:hover': {
        //   textOverflow: 'clip',
        //   whiteSpace: 'normal',
        //   lineHeight: 1,
        // },

        /** actions */
        '& .MuiDataGrid-actionsCell .MuiIconButton-root': {
          color: themeSettings.palette.primary.main,
        },

        '& .MuiDataGrid-cell.vertical': {
          minWidth: '24px !important',
          maxWidth: '24px !important',
        },

        '& .MuiDataGrid-cell.vertical > span': {
          position: 'absolute',
          top: '50%',
          right: '50%',
          transform: 'scale(-1) translate(-50%, 50%)',
          writingMode: 'vertical-lr',
          textAlign: 'center',
        },

        /** calendar */
        ...dataGridThemeCalendar(),
      },
    },
  },

  MuiPaper: {
    styleOverrides: {
      root: {
        '& .expandable': {
          overflow: 'hidden',
          width: 32,
          transition: 'all 0.5s linear',
          borderRadius: 3,
          backgroundColor: colors.lightIconWhite,
          maxHeight: 32,
        },

        '& .expandable .searchButton': {
          padding: 4,
        },

        '& .expandable *': {
          color: colors.cobalt,
        },

        '& .expandable.expand': {
          width: 450,
          boxShadow: `inset 0 0 0 1px ${colors.lightDividerBlack}`,
          outline: `solid 1px ${colors.cobalt}`,
        },

        '& .advFiltering': {
          overflow: 'hidden',
          height: 0,
          padding: '0 var(--spacing3)',
          backgroundColor: colors.veryLightBlue,
          transition: 'all 0.5s ease-in-out',
        },

        '& .advFiltering.expand': {
          height: 80,
        },

        '& .iconButton.expand': {
          backgroundColor: colors.veryLightBlue,
        },
      },
    },
  },
});
