import type {Theme} from '@mui/material';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';

/**
 * todo boxShadows have a theme setting
 *
 * Notes:
 *    avoid using colors const and hardcoded colors to allow changing themes
 *    color cannot be a css variable but otherwise try to use css variables
 *
 */
export const accordionTheme = (themeSettings: Theme): Theme['components'] => ({
  MuiAccordion: {
    styleOverrides: {
      root: {
        margin: 0,
        marginBottom: 0,
        border: 'solid 1px var(--lightDividerBlack)',
        borderRadius: '4px',
        color: themeSettings.palette.text.primary,
        boxShadow: 'none',

        '&.Mui-expanded': {
          margin: 0,
        },

        '& .question-step.MuiAccordion-region': {
          backgroundColor: 'var(--lightTableRowOdd)',
        },

        '&.fill.Mui-expanded': {
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        },

        // To fill the accordion details to available vertical space, all containing divs must also fill.
        // There that nested divs fro the accordion root to the accordion details and each needs this rule.
        '&.fill.Mui-expanded > .MuiCollapse-root, &.fill.Mui-expanded > .MuiCollapse-root > *, &.fill.Mui-expanded > .MuiCollapse-root > * > *, &.fill.Mui-expanded > .MuiCollapse-root > * > * > *, &.fill.Mui-expanded > .MuiCollapse-root > * > * > * > *':
          {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
          },
      },
    },
  },

  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: themeSettings.spacing(2),
        overflow: 'visible',

        '&.fill > *': {
          flexGrow: 1,
        },
      },
    },
  },

  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        minHeight: '40px',
        fontSize: '16px',
        fontWeight: '600' as any,
        borderBottom: '1px solid var(--lightDividerBlack)',
        padding: '8px 20px',

        '&.Mui-expanded': {
          minHeight: '40px',
        },

        '& .MuiAccordionSummary-expandIconWrapper': {
          color: themeSettings.palette.primary.main,
        },

        '&:focus': {
          outline: 'none',
        },
      },

      content: {
        '&.Mui-expanded': {
          margin: 0,
        },

        margin: 0,
      },
    },
  },
});
