import colors from '../colors';

// todo type error for MuiPickerStaticWrapper
export const dateTimeTheme = (): {
  MuiPickerStaticWrapper: {
    styleOverrides: {
      root: {
        '&.dense .MuiPickersDay-root.Mui-selected': {color: string};
        '&.dense .MuiPickersDay-root:not(.MuiPickersDay-dayOutsideMonth), &.dense [role=columnheader]': {color: string};
        '& .MuiPickersCalendarHeader-root': {display: string};
        '&.with-headers .MuiPickersCalendarHeader-root': {display: string};
        '&.hideDefaultPickers .MuiCalendarPicker-root > *:not(.MuiCalendarPicker-viewTransitionContainer)': {
          outline: string;
          display: string;
        };
        '&.dense [role=columnheader]': {color: string; fontSize: string; fontWeight: number};
        '&.dense .MuiCalendarPicker-root': {boxShadow: string; margin: string; width: string; maxWidth: number};
        '&.dense .MuiPickersDay-root:hover': {backgroundColor: string; color: string};
        '&.dense .MuiPickersDay-root, &.dense [role=columnheader]': {
          margin: string;
          borderRadius: string;
          width: number;
          fontSize: string;
          fontWeight: number;
          height: number;
        };
      };
    };
  };
  MuiPickersDay: {
    styleOverrides: {
      root: {
        borderRadius: string;
        '&.MuiPickersDay-dayOutsideMonth': {color: string; fontWeight: string};
        '&.Mui-selected': {color: string; fontWeight: number};
        fontSize: string;
        fontWeight: number;
      };
    };
  };
} => ({
  MuiPickerStaticWrapper: {
    styleOverrides: {
      root: {
        '& .MuiPickersCalendarHeader-root': {
          display: 'none',
        },

        '&.with-headers .MuiPickersCalendarHeader-root': {
          display: 'flex',
        },

        '&.dense .MuiCalendarPicker-root': {
          width: 'auto',
          margin: '5px 0',
          maxWidth: 150,
          boxShadow: 'none',
        },

        '&.hideDefaultPickers .MuiCalendarPicker-root > *:not(.MuiCalendarPicker-viewTransitionContainer)': {
          outline: '1px solid blue',
          display: 'none',
        },

        '&.dense [role=columnheader]': {
          color: colors.dark,
          fontSize: '14px',
          fontWeight: 600,
        },

        '&.dense .MuiPickersDay-root, &.dense [role=columnheader]': {
          width: 15,
          height: 15,
          margin: '1px 3px',
          fontSize: '10px',
          fontWeight: 300,
          borderRadius: '50%',
        },

        '&.dense .MuiPickersDay-root:not(.MuiPickersDay-dayOutsideMonth), &.dense [role=columnheader]': {
          color: colors.dark,
        },

        '&.dense .MuiPickersDay-root.Mui-selected': {
          color: colors.calcite,
        },

        '&.dense .MuiPickersDay-root:hover': {
          backgroundColor: colors.dark,
          color: colors.calcite,
        },
      },
    },
  },

  MuiPickersDay: {
    styleOverrides: {
      root: {
        borderRadius: '3px',
        fontSize: '14px',
        fontWeight: 600,

        '&.Mui-selected': {
          fontWeight: 600,
          color: colors.calcite,
        },

        '&.MuiPickersDay-dayOutsideMonth': {
          color: colors.lightIconDisabled,
          fontWeight: 'normal',
        },
      },
    },
  },
});
